.itenairyDetailUI {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.itenairyDetailBox {
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 10px;
}

.itenairyDetailBox1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.backgroundImageItenairy {
  width: 55%;
  padding: 20px;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  /* Ensure that the gradient does not overflow */
}

.itenairyDetailBgImg {
  /* height: 360px; */
  /* border-radius: 30px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 0px;
  background-repeat: no-repeat;
  background-size: cover;
}

.itenaryDetailBgImgDetails {
  padding: 0px 30px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 2;
}

.widthResponsive {
  width: 55%;
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding: 20px;
}

.daysFilterScroll {
  display: flex;
  gap: 10px;
  overflow-x: auto;
  webkit-overflow-scrolling: touch;
}

.itenaryDaysFilterSelection {
  background-color: #f1f1f1;
  border-radius: 40px;
  padding: 7px 10px;
  width: 41px;
  height: 66px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #666666;
}

.itenaryDaysFilterSelection.selected {
  background-color: #333333;
  color: #ffffff;
}

.stickyMap {
  position: fixed;
  top: 0;
  /* Adjust this value based on your layout */
  height: 100vh;
  /* Full viewport height */
  right: 0;
  width: 40%;
}

@media (max-width: 768px) {
  .backgroundImageItenairy {
    width: 100%;
    padding: 0px;
    margin-bottom: 15px;
  }

  /* Adjust the breakpoint as needed */
  .itenairyDetailBgImg::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    /* background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0)); */
    /* z-index: 1; */
    border-radius: 30px;
    /* Ensure the gradient is above the background image */
  }

  .itenairyDetailBgImg {
    position: relative;
    z-index: 0;
    /* Ensure the background image is behind the gradient */
  }

  .itenaryDetailBgImgDetails {
    display: none;
  }

  .itenaryDetailBgImgDetailsMobile {
    display: block;
    background-color: #ffffff;
    box-shadow: 0px 3px 28px 3px #0000000a;
    border-radius: 20px;
    padding: 15px;
    margin: 9px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .widthResponsive {
    width: 100%;
  }

  .stickyMap {
    display: none;
  }
}

@media (min-width: 768px) {
  .itenairyDetailBgImg {
    height: 240px;
    border-radius: 0px !important;
  }

  .itenaryDetailBgImgDetailsMobile {
    display: none;
  }
}
