.itenaryDetailsCard {
  background: #ffffff;
  box-shadow: 0px 3px 28px 3px #0000000a;
  border-radius: 20px;
  padding: 7px;
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.detailPageUI {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}

.detailPageBox {
  padding: 14px 10px;
  display: flex;
  gap: 4px;
  justify-content: end;
}

.truncatedStyle {
  font-size: 12px;
  color: #999999;
  font-weight: 500;
  font-family: Mulish;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.fullTextStyle {
  font-size: 12px;
  color: #999999;
  font-weight: 500;
  font-family: Mulish;
}

.appleCardsContainer {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer and Edge */
}

.appleCardsContainer::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, and Opera */
}

.appleCard {
  display: inline-block;
  vertical-align: top;
  /* Add any additional styling for the card */
}
