/* NOTE the order of these styles DO matter */

/* Will edit everything selected including everything between a range of dates */
.CalendarDay__default {
  border-color: #fff;
}

.CalendarDay__selected_span {
  background: #eaeaea;
  color: #333;
  border: 1px solid #eaeaea;
}

.CalendarDay__selected_span:hover {
  background: #eaeaea;
  color: #333;
  border: 1px solid #eaeaea;
}

/* Will edit selected date or the endpoints of a range of dates */
.CalendarDay__selected {
  background: #333;
  color: white;
  border: none;
}

.CalendarDay__selected_start {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.CalendarDay__selected_end {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

/* Will edit when hovered over. _span style also has this property */
.CalendarDay__selected:hover {
  background: #333;
  color: white;
  border: none;
}

/* Will edit when the second date (end date) in a range of dates is not yet selected. Edits the dates between your mouse and said date */
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #eaeaea;
  border: 1px solid #eaeaea;
  color: #333;
}

.DateRangePicker_picker {
  z-index: 999;
}

.DateInput_input {
  font-size: 16px;
  font-family: Mulish, sans-serif;
  outline: none;
}

.DateInput,
.DateInput_input {
  width: 150px;
}
