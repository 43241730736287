@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiAppBar-root {
  background-color: unset !important ;
  box-shadow: none !important;
}

.slick-dots {
  display: flex !important;
  justify-content: center;
  align-items: center;
  bottom: -20px;
  padding: 0;
  margin: 0;
}

.slick-dots li {
  width: auto;
  height: auto;
}

.slick-dots li button:before {
  font-size: 12px;
  color: #fff;
}

.slick-dots li.slick-active button:before {
  color: #000;
}

.loader {
  width: 60px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side,#20B08F 90%,#0000);
  background: 
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 50%;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
    20%{background-position:0%   0%, 50%  50%,100%  50%}
    40%{background-position:0% 100%, 50%   0%,100%  50%}
    60%{background-position:0%  50%, 50% 100%,100%   0%}
    80%{background-position:0%  50%, 50%  50%,100% 100%}
}

/* Custom slider styles */
input[type="range"] {
  -webkit-appearance: none;
  @apply w-full h-1;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  @apply w-4 h-4 rounded-full bg-black cursor-pointer pointer-events-auto relative z-50;
}

input[type="range"]::-moz-range-thumb {
  @apply w-4 h-4 rounded-full bg-black cursor-pointer pointer-events-auto relative z-50 border-0;
}

input[type="range"]:focus {
  outline: none;
}